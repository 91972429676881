$(document).ready(function ($) {
  /* modal */
  $('.popup-frame-close').click(function () {
    $(this).parents('.popup-fade').fadeOut(100)
    return false
  })

  $(document).keydown(function (e) {
    if (e.keyCode === 27) {
      e.stopPropagation()
      $('.popup-fade').fadeOut(100)
    }
  })

  $('.popup-fade').click(function (e) {
    if ($(e.target).closest('.popup').length == 0) {
      $(this).fadeOut(100)
    }
  })

  $('.popup-open-android').click(function () {
    $('.popup-fade').fadeIn(100)
    openModal()
    return false
  })
  $('.popup-open-ios').click(function () {
    $('.popup-fade').fadeIn(100)
    return false
  })

  $('.popup-frame-close').click(function () {
    $(this).parents('.popup-fade').fadeOut()
    return false
  })

  $(document).keydown(function (e) {
    if (e.keyCode === 27) {
      e.stopPropagation()
      $('.popup-fade').fadeOut(100)
    }
  })

  $('.popup-fade').click(function (e) {
    if ($(e.target).closest('.popup').length == 0) {
      $(this).fadeOut(100)
    }
  })

  $('.frame-parent2 .frame-wrapper1').click(function () {
    $('.panel').hide()
    $('.frame-parent2 .frame-wrapper1.active').removeClass('active')
    $(this).addClass('active')

    let panel = $(this).attr('href')
    $(panel).fadeIn(100)

    return false
  })

  $('.popup-download-buttons .popup-platform-buttons').click(function () {
    $('.platform').hide()
    $('.popup-download-buttons .popup-platform-buttons.active').removeClass(
      'active'
    )
    $(this).addClass('active')

    let popup = $(this).attr('href')
    $(popup).fadeIn(100)

    return false
  })

  $('.popup-download-buttons .popup-platform-buttons:first').click()

  function openModal() {
    const windowInnerHeight = window.innerHeight
    const popup = $('.popup').innerHeight()
    console.log(windowInnerHeight)
    if (windowInnerHeight <= popup) {
      console.log(popup)
    }
  }

  /* modal */
  /* swiper modal mobile */

  const mobile = window.matchMedia('(min-width: 0px) and (max-width: 640px)')
  const tablet = window.matchMedia('(min-width: 641px) and (max-width: 1023px)')
  const desktop = window.matchMedia('(min-width: 1024px)')
  let swiper = new Swiper()

  function swiperMode() {
    let init = false
    swiper.destroy()
    if (mobile.matches || tablet.matches) {
      if (!init) {
        swiper = new Swiper('.mySwiper', {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        })
      }
    } else if (desktop.matches) {
      swiper.destroy()
      init = false
    }
  }

  swiperMode()

  $(window).resize(function () {
    swiperMode()
  })

  /* swiper modal mobile */
  /* sticky button */

  function elem_in_visible_area(selector) {
    let elem_p = $(selector),
      offset_top_el = elem_p.offset().top,
      offset_bottom_el = offset_top_el + elem_p.height(),
      scrolled = $(window).scrollTop(),
      scrolled_bottom = scrolled + $(window).height()
    if (scrolled_bottom > offset_top_el && offset_bottom_el > scrolled) {
      return true
    }
    return false
  }

  const block = document.querySelector('.buttons-mobile-sticky')

  $(window).scroll(function () {
    if (elem_in_visible_area('.rd-wrapper')) {
      block.style.opacity = '0'
    } else if (elem_in_visible_area('.st')) {
      block.style.opacity = '0'
    } else {
      block.style.opacity = '1'
    }
  })
  /* sticky button */

  document
    .querySelectorAll('.two-black-dices-with-different-icon')
    .forEach((dice) => {
      dice.addEventListener('mousemove', (event) => {
        const rect = dice.getBoundingClientRect()
        const x = event.clientX - rect.left
        const y = event.clientY - rect.top
        const moveX = (x / rect.width - 0.5) * 20
        const moveY = (y / rect.height - 0.5) * 20
        dice.style.transform = `translate(${moveX}px, ${moveY}px)`
      })

      dice.addEventListener('mouseleave', () => {
        dice.style.transform = 'translate(0, 0)'
      })
    })

  $(window).on('scroll', function () {
    $('.hidden-element').each(function () {
      var elementTop = $(this).offset().top
      var elementMid = elementTop + $(this).outerHeight() / 2
      var viewportBottom = $(window).scrollTop() + $(window).height()

      if (viewportBottom > elementMid) {
        $(this).addClass('visible')
      }
    })
  })
})

$(document).ready(function () {
  const mobile = window.matchMedia('(min-width: 0px) and (max-width: 640px)')
  let isAnimating = false

  function setWidths() {
    if (mobile.matches) {
      return
    }
    $('.group-parent-wrapper').each(function (index) {
      if (index < 3) {
        $(this).css('width', 518 - index * 16 + 'px')
      } else {
        $(this).css('width', 470 + 'px')
      }
    })
  }

  setWidths()

  //   function animation(isAnimating) {
  //     if (mobile.matches) {
  //       return
  //     }
  //     if (isAnimating) return

  //     let $items = $('.group-parent-wrapper')

  //     let heights = $items
  //       .map(function () {
  //         return $(this).outerHeight(true) + 8
  //       })
  //       .get()

  //     let widths = $items
  //       .map(function () {
  //         return parseInt($(this).css('width')) + 16
  //       })
  //       .get()

  //     $items
  //       .eq(0)
  //       .css({ 'z-index': '-1', opacity: 0.01 })

  //       .animate(
  //         {
  //           marginTop: `-${heights[0]}px`,
  //           width: widths[0] + 'px',
  //         },
  //         {
  //           duration: 300,
  //           easing: 'linear',
  //         }
  //       )
  //       .css({ 'z-index': '-1' })
  //       .fadeOut(500, 'linear', function () {
  //         let $detachedItem = $(this).detach()
  //         $('.group-parent').append($detachedItem)
  //         $detachedItem
  //           .css({
  //             marginTop: '0',
  //             opacity: 1,
  //             'z-index': 'auto',
  //           })
  //           .fadeIn()
  //       })

  //       .eq(1)
  //       .animate({ opacity: 'auto', width: widths[1] + 'px' }, 100, 'linear')
  //       .eq(2)
  //       .animate({ opacity: 'auto', width: widths[2] + 'px' }, 100, 'linear')
  //     setWidths()
  //   }

  //   $('.feature-icons3').hover(
  //     function () {
  //       animation(true)
  //     },
  //     function () {
  //       animation(false)
  //     }
  //   )
})
